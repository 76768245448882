export const PATIENT = "Пациент"
export const VERSION = "Версия"
export const MODIFICATION = "Модификация"
export const CREATE_AND_SWITCH_TO_VERSION = "Создать версию и переключиться"
export const SWITCH_TO_VERSION = "Переключиться"
export const APPROVED_0 = "Утвержден"
export const CURRENT = "Текущая"
export const NO = "Нет"
export const YES = "Да"
export const LOADING = "Loading..."
export const PLAN = "План"
export const PLAN_ID = "ID плана"
export const PARENT_PLAN= "Родительский план"
export const LK_LINK= "Ссылка на версию плана в ЛК"
export const PUBLICATION_DATE= "Дата публикации в лк"
export const APPROVE_DATE= "Дата утверждения в лк"
export const PUBLISHED= "Опубликовано"
export const CREATION_DATE= "Дата создания"
export const TECHNICIAN= "Техник"
export const DOCTOR= "Врач"
export const AUTHOR= "Автор"
export const APPROVED_1= "Утверждено"
export const CHANGES= "Изменения"
export const DOCTORS_COMMENT= "Комментарий врача"
export const VISIT_NUMBER= "Номер визита"
export const REGION= "Регион"
export const UPLOAD_DATA= "Дата загрузки модели"
export const STATUS= "Статус"
export const MODELLER_LINK= "Ссылка в моделировщике"
export const CROWN_CASE_ID= "ID плана в ЛК"
export const BUTTON_TO_MODELER= "Перейти в моделировщик"
export const STATUS_LIST={
    'S_OCCLUSAL_PLANE_SETUP': 'Occlusal Plane Setup',
    'S_OCCLUSAL_PLANE': 'Occlusal Plane',
    'S_TRIM_BASE': 'Trim Base',
    'S_GENERATE_BASE': 'Generate Base',
    'S_TEETH_MARKUP': 'Teeth Markup',
    'S_CROWN_SEGMENTATION': 'Crown Generation',
    'S_SEGMENTED_ASSET_CREATION': 'Asset Creation',
    'S_LOCAL_C_S': '',
    'S_SETUP_QA': 'Setup QA',
    'S_MODELLING': 'Modelling',
    'S_SETUP_FAKE_MANAGEMENT': 'Setup Fake Management',
    'S_CLINICIAN_REVIEW': 'Clinician Review',
}
export const PLAN_LIST={'Treatment plan': 'Первичный план', 'Correction': 'Коррекция'}