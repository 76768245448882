import React from 'react';
import { PlanDescription } from '../../planDetailPage/planDescription/index';
import { Collapse, Badge, Button, Skeleton } from 'antd';
import {
    SWITCH_TO_VERSION,
    APPROVED_0,
    CURRENT,
    PUBLISHED,
    VERSION,
    BUTTON_TO_MODELER,
    MODIFICATION,
} from "../../../constants/ru_strings";
import { MODELLER_URL } from '../../../constants/urls';

const { Panel } = Collapse;

export const Plans = (props) => {

    return (
        <div>
            <Collapse key={`1${Math.random()}`} defaultActiveKey={[...Array(Object.keys(props.plans).length).keys()]}>
                {!props.loading ? Object.keys(props.plans).map((key, index) => (
                    <Panel
                        header={
                            props.plans[key].is_modification ?
                                `${MODIFICATION} ${props.plans[key]['index']}` :
                                `${VERSION} ${props.plans[key]['index']}`
                        }
                        key={index}
                        extra={[
                            props.plans[key].approved
                                ? <Badge
                                    key={`plan.version_${Math.random()}`}
                                    count={1 ? APPROVED_0 : 0}
                                    style={{ backgroundColor: '#52c41a', marginLeft: '5px' }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                />
                                : <span key={`plan.version_${Math.random()}`}></span>,

                            props.plans[key].active
                                ? <Badge
                                    key={`plan.version_${Math.random()}`}
                                    count={1 ? CURRENT : 0}
                                    style={{ backgroundColor: '#52c41a', marginLeft: '5px' }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                />
                                : <span key={`plan.version_${Math.random()}`}></span>,

                            props.plans[key].published
                                ? <Badge
                                    key={`plan.version_${Math.random()}`}
                                    count={1 ? PUBLISHED : 0}
                                    style={{ backgroundColor: '#52c41a', marginLeft: '5px' }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                />
                                : <span key={`plan.version_${Math.random()}`}></span>,

                            (!props.plans[key].active && !props.is_approved)
                                ? <Button
                                    key={`plan.version_${Math.random()}`}
                                    type="primary"
                                    onClick={
                                        (e) => {
                                            e.stopPropagation();
                                            // props.plans[key].author && props.plans[key].author.role === 'doctor' ? props.switchAndCreatePlan(props.plans[key]) : props.switchPlan(props.plans[key].id);
                                            props.switchPlan(props.plans[key].id);
                                        }
                                    }
                                    style={{ marginLeft: '5px' }}
                                // >{props.plans[key].author && props.plans[key].author.role === 'doctor' ? CREATE_AND_SWITCH_TO_VERSION : SWITCH_TO_VERSION}</Button>
                                >{SWITCH_TO_VERSION}</Button>
                                : <span key={`plan.version_${Math.random()}`}></span>,

                            <Button
                                key={`plan.version_${Math.random()}`}
                                type="primary"
                                onClick={
                                    (e) => {
                                        e.stopPropagation();
                                        window.open(
                                            `${MODELLER_URL}/?uuid=${props.treatment_id}`,
                                        );
                                    }
                                }
                                style={!props.plans[key].active ? { 'display': 'none' } : { marginLeft: '5px' }}
                            >{BUTTON_TO_MODELER}</Button>,
                        ]}>
                        <PlanDescription
                            author={props.plans[key].author}
                            parent={props.plans[key].parent_plan !== null ? `${props.plans[key].parent_plan}` : ''}
                            approved={props.plans[key].approved}
                            plan={props.plans[key]}
                            modeling_data={props.plans[key]['modeling_data']}
                            modeling_data_diff={props.plans[key]['modeling_data_diff']}
                            url={`${MODELLER_URL}/?uuid=${props.treatment_id}`}
                            plans={props.plans}
                        />
                    </Panel>
                )) : <div>{<Skeleton />}</div>}
            </Collapse>
        </div>
    )
}