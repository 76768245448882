import { Descriptions, Badge } from 'antd';
import moment from 'moment';
// import { isNull } from 'lodash';
// import { useState, useEffect } from 'react';

import {
  PLAN_ID,
  PARENT_PLAN,
  PUBLICATION_DATE,
  CREATION_DATE,
  DOCTOR,
  AUTHOR,
  DOCTORS_COMMENT,
  CROWN_CASE_ID,
  VERSION,
  MODIFICATION,
  APPROVE_DATE
} from "../../../constants/ru_strings";

const hidden = {
    'display': 'none',
}

// const ModellerLink = (props) => {
//   const [clicked, setClicked] = useState(false);

//   useEffect(() => {
//     if (clicked) {
//       window.open(
//         props.url,
//         '_blank' // <- This is what makes it open in a new window.
//       );
//     }
//   });

//   return (
//     <a onClick={() => setClicked(true)}>{props.url}</a>
//   );
// };

export const PlanDescription = (props) => {

  const date = moment(props.plan.created).format("lll")
  const published_date = props.plan.publish_date ? moment(props.plan.publish_date).format("lll") : ''
  // const role = !isNull(props.plan.author) ? props.plan.author.role : "";
  const parent_version = props.parent ? `${VERSION} ${props.plans[props.parent]['index']}` : ''
  const modification_index = props.plans[props.plan.modification] ? props.plans[props.plan.modification]['index'] : ''
  const plan_description = props.plan.description
  const approved = props.plan.approved
  const approve_date = approved ? moment(props.plan.approve_date).format("lll") : null

  return (
      <>
        <Descriptions title="" size="small" bordered>
          <Descriptions.Item label={PLAN_ID} span={3} style={hidden}>
            <Badge status="processing" text={props.id} />
          </Descriptions.Item>
          <Descriptions.Item label={PARENT_PLAN} span={3} style={!props.parent ? hidden : {}} >
            {/* <Badge status="processing" text={props.parent.split('-')[0]} /> */}
            {/* <Badge status="processing" text={props.parent ? props.plans[props.parent['index']] : 'aaa'} /> */}
            <Badge status="processing" text={parent_version} />
          </Descriptions.Item>
          {/* <Descriptions.Item
            label={LK_LINK}
            style={role === 'technician' ? {} : hidden}
            span={3}>
              <a href={TAM_URL}>{props.lk_id}</a>
          </Descriptions.Item> */}
          {/* <Descriptions.Item label={role === 'technician' ? PUBLICATION_DATE : CREATION_DATE} labelStyle={{'width': '30%'}} span={3}>{date}</Descriptions.Item> */}
          <Descriptions.Item label={CREATION_DATE} labelStyle={{'width': '30%'}} span={3}>{date}</Descriptions.Item>
          <Descriptions.Item label={PUBLICATION_DATE} labelStyle={{'width': '30%'}} span={3} style={published_date.length === 0 ? hidden : {}}>{published_date}</Descriptions.Item>
          {
            approve_date ?
            <Descriptions.Item label={APPROVE_DATE} labelStyle={{'width': '30%'}} span={3}>{approve_date}</Descriptions.Item>
            :
            <></>

          }
          <Descriptions.Item label={AUTHOR} span={3}>{ !props.plan.is_modification ? props.plan.author.login : DOCTOR }</Descriptions.Item>
          {
            !props.plan.is_modification && props.plan.modification ?
            <Descriptions.Item label={MODIFICATION} span={3}>{ modification_index }</Descriptions.Item>
            :
            <></>

          }
          
          {/* <Descriptions.Item label={APPROVED_1} span={3}>{props.approved ? YES : NO}</Descriptions.Item> */}
          {/* <Descriptions.Item label={CHANGES} span={3}>
           no_diff
            {JSON.stringify(props.modeling_data_diff)}
          </Descriptions.Item> */}
          {props.plan.is_modification ? 
            <Descriptions.Item label={DOCTORS_COMMENT} span={3}>
              {plan_description && props.plan.is_modification ? plan_description : "Нет комментариев"}
            </Descriptions.Item>
          :
            <></>
          }
          
          {/* <Descriptions.Item label={MODELLER_LINK} span={3}>
            {<ModellerLink url={props.url}/>}
          </Descriptions.Item> */}
          <Descriptions.Item label={CROWN_CASE_ID} span={3} style={props.plan.is_modification || !props.plan.crown_case_id ? hidden : {}}>
            {props.plan.crown_case_id}
          </Descriptions.Item>
        </Descriptions>
      </>
    )
}